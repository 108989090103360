<template>
    <div class="container">
        <div class="header">
            <h1>Help/Info Page</h1>
            <p>6-a-side Football Team Picker</p>
        </div>
        <div class="body">
            <h3>How to use the team selector</h3>
            <p>
                The teams will be decided by using the ratings given for each player and attemping to make the teams
                as equal as possible, based on the ratings given.
            </p>
            <div class="help-ratings">
                <div class="rating-bad">
                    <p>1</p>
                    <p>Bad</p>
                </div>
                <div class="rating-medium">
                    <p>5</p>
                    <p>Alright</p>
                </div>
                <div class="rating-good">
                    <p>10</p>
                    <p>Good</p>
                </div>
            </div>
            <p>Once you have entered each players name and have added them to the Player List,
                click the "Generate Teams" button to see the teams that have been selected.
            </p>
            <p>
                If you are not happy with the teams, click 'Randomize Teams' and you can randomize
                them as many times as you like until you are happy with them.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HelpPage'
}
</script>

<style>
    @import "../assets/css/helpPage.css";
</style>