import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import HelpPage from '../components/HelpPage.vue';
import TeamIcons from '../components/TeamIcons.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage
    },
    {
        path: '/help',
        name: 'Help',
        component: HelpPage
    },
    {
        path: '/team-icons',
        name: 'Team Icons',
        component: TeamIcons
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = to.name;
    next();
});

export default router;