<template>
    <div class="container">
        <div class="header">
            <h1>Team Icons</h1>
            <p>Player Names that have icons</p>
        </div>
        <div class="body">
            <h3>How to use the team selector</h3>
            <p>
                Here you can find a list of all the players names that currently
                have icons. If you want an icon to be added for your name, send
                me a photo and I'll get it added.
            </p>

        </div>
        <div class="player-icons-container">
            <div class="player-item">
                <span>Ben</span>
                <img src="@/assets/img/player-icons/cz-ben.png" alt="Ben's Icon" class="player-image" />
            </div>
            <div class="player-item">
                <span>Max</span>
                <img src="@/assets/img/player-icons/cz-max.png" alt="Max's Icon" class="player-image" />
            </div>
            <div class="player-item">
                <span>Callum</span>
                <img src="@/assets/img/player-icons/cz-callum.png" alt="Callum's Icon" class="player-image" />
            </div>
            <div class="player-item">
                <span>Ben</span>
                <img src="@/assets/img/player-icons/cz-ben.png" alt="Ben's Icon" class="player-image" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TeamIcons'
}
</script>

<style>
    @import "../assets/css/teamIcons.css";
</style>